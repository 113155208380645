.cart-uper-hader{
    display: flex;
    text-align: center;
    color: black;
    /* padding-top: 16px; */
    height: 50px;
    background-color: white;
    position: fixed;
    width: 100%;
    font-size: 20px;
    font-weight:500;
    align-items: center;
    justify-content: space-evenly;
}

.modal-icons-cards{
    display: flex;
    padding-top: 7px;
    margin-left: 22px;
    margin-right: 22px;
    padding-bottom: 7px;
    margin-bottom: 5px;

    /* outline: 2px solid black; */
    border-radius: 15px;
    /* background-color: burlywood; */
    /* overflow: hidden; */
}

.modal-card-text{
    margin-left: 7px;
    display: flex;
    align-items: center;
    color: black;
    font-weight:600;
    font-size: 17px;
    padding-left: 5px;
}


.modal-icons-cards:hover{
  

    /* outline: 2px solid black; */
   cursor: pointer;
    background-color: rgba(156, 156, 156, 0.374);
   
}



.modal-icon-img{
    padding-left: 7px;
    border-radius: 10px;
    /* overflow: hidden; */
}

.mobile-nav-btn-padding{
    padding-bottom: 25px;
    overflow: hidden;
}

.ladger-btn-for-mobileNav{
    font-size: 20px;
    height: 54px;
    background-color: rgba(188, 192, 243, 0.471); /* Set the background color */
    color: rgb(0, 0, 0); /* Set the text color */
    border: 2px solid rgb(234, 234, 242); /* Set the outline border color and width */
    border-radius: 10px; /* Adjust the border radius as needed */
    padding: 5px 30px;
    align-items: center;
    display: flex;
    margin-left: 7%;
    
  
}
