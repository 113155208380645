/* Default styles */

.button-container {
  display: block;
  float: right;
  /* margin-right: -5%; */
  position: relative;
  top: -60px;

  margin-right: 12%;
  /* display: grid; */
  grid-row-gap: 20px;
}

.imgdecoration {
  padding-left: 200px;
  padding-right: 200px;
  width: 100%;
  height: auto;
  margin-top: 20px;
}













.heading-top-margin{
  padding-top: 30px;}



.stack-and-btn-flex{

  display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-left: 10%;
    justify-content: space-between;
    margin-right: 10%;
    /* margin: 10px; */
    padding: 0px;
    padding-bottom: 20px;
}

.stake-fonts-syt{
  font-size: 55px;
  color: black;
  font-weight: 70px;
}
.stake-text-font{
  color: rgb(213, 211, 211);
  display: flex;
}


.usd, .usd-1{
border: none;
background-color: #113974;
color: black;
padding: 10px;
border-radius: 10px;
text-align: center;
}

.usd-active, .usd-1-active {
  background-color: rgb(149, 189, 202);
}

.usd-and-joe-btn-outline{
  outline: 2px solid black;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px;
  height: 50px;
}

.all-cards-outer-body-adjustment{
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  padding-bottom: 20px;
    padding-top: 20px;
    padding-left: 10%;
    padding-right: 10%;

}

.card1-outer-body-whole{
  display: flex;
  width: 100%;
  /* padding: 13px; */
  margin: 13px;
  box-shadow: 10px 15px 10px black;
  /* flex-direction: column; */
}

.card-1-body-spacing{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* justify-content: space-around; */
  padding: 13px;

  width: 100%;
  outline: 3px solid rgb(104, 104, 104);
  /* background-color: rgb(202, 202, 202); */
  background-color: rgb(255, 250, 250);

  border-radius: 5px;
}


.in-second-row-card{
  padding-right: 200px;
}

.cards-fields-font-sizes{
  font-size: 15px;
  color: rgb(142, 138, 138);
}
.cards-field-numbwe-color{
  font-size: 21px;
  color: black;
}

.in-card-earn-btn-outer{
  outline: black solid 2px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 7px;
  box-shadow: 8px 10px 10px black;

}
.in-card-earn-btn-outer:hover{
  outline: rgb(255, 245, 245) solid 2px;
  background-color: rgb(195, 195, 198);
  cursor: pointer;

}

.card-earn-btn-inner{
  color: gray;
  font-size: 15px;
}

.card-img-sizing-multiple{
  height: 47px;
  width: auto;
}




@media screen and (max-width: 1380px) {
.in-second-row-card{
  padding-right: 0px;
}
}

@media screen and (max-width: 490px) {

  .stake-text-font{
    display: none;
  }
.usd-and-joe-btn{
  border: none;
  background-color: transparent;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  }
  
  .usd-and-joe-btn-outline{
    outline: 2px solid black;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px;
    margin-right: 0px;
    height: 40px;
  }  
  }
  
  




@media screen and (max-width: 770px) {
.uper-4-btn-hide{
  display: none;
}


.all-cards-outer-body-adjustment{

  flex-wrap: wrap;
}


.all-cards-outer-body-adjustment{
 
    padding-left: 1%;
    padding-right: 1%;

}



.in-card-earn-btn-outer{
  outline: black solid 2px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 6px;
  /* box-shadow: 1px 4px 10px black; */

}
.card-earn-btn-inner{
  color: gray;
  font-size: 10px;
}


.cards-fields-font-sizes{
  font-size: small;
  color: rgb(142, 138, 138);
}
.cards-field-numbwe-color{
  font-size:medium;
  color: black;
}

.card-img-sizing-multiple{
  height: 26px;
  width: 40px;
}



}














.ppp {
  margin-left: 10%;
}

.secondpagemargin{
  margin: 0 auto !important;
}

.but-1 {
  border: none;
  background-color: transparent;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  text-align: center;
}

.outer-outline {
  outline: 2px solid black;
  border-radius: 20px;

  margin-bottom: 20px;

  /* flex-wrap: wrap; */
  /* align-content: space-around; */
  align-items: center;
}

.data {
  margin-left: 10%;
  position: relative;
  top: 20px;
}

.sb {
  position: relative;
  top: -15px;
}

.but-1:hover {
  background-color: lightgrey;
}

.ch {
  width: 200%;
  height: auto;
  /* margin-left: 5%;
  margin-top: 5%; */
}

.ty {
  margin-top: 10%;
  margin-left: 6%;
  /* margin-bottom: 90px; */
  overflow: hidden;
}

.txt {
  margin-left: 7%;
  margin-top: -4%;
}

.mm {
  margin-left: 3%;
  margin-top: 3%;
  margin-bottom: 3px;
}

.gg {
  margin-top: 5px;
  margin-left: 3%;
}

.is {
  margin-top: -2.5%;
}

.liquid,
.blah {
  color: lightgray;
}

.textstack {
  position: relative;
  left: 500px;
  top: -50px;
}

.cart-content-stack1 {
  display: flex;
  font-size: large;
  font-weight: lighter;
  margin-bottom: 1%;
}

.cart-content-stack2 {
  color: black;
  display: flex;
  margin-bottom: 2%;
}

.stackedbtn {
  margin-left: 5%;
}
.yourstacked {
  margin-left: 40%;
}
.depositefee {
  margin-left: 50%;
}

.prices {
  margin-left: 7%;
  font-size: 25px;
}
.prices1 {
  margin-left: 56%;
  font-size: 25px;
}

.cardsizing-pool {
  /* width: 50%; */
  width: 53rem;
  /* height: auto; */
  height: 19rem;
  border-radius: 20px;
  margin-left: 3%;
  margin-bottom: 5%;
}

/* Media query for screens up to 1024px wide */
@media screen and (max-width: 1024px) and (min-width: 701px) {
  /* Update the styles here for screens between 601px and 1024px wide */
  .button-container {
    float: right;
    /* margin-right: -30%; */
    position: relative;
    top: 10px;

    position: relative;
    top: -60px;

    margin-right: 12%;
    /* display: grid; */
    grid-row-gap: 20px;
  }

  .cardsizing-pool {
    /* width: 50%; */
    width: 40rem;
    /* height: auto; */
    height: 18rem;
    border-radius: 20px;
  }

  .but-1 {
    width: 45px;
    height: 45px;
  }

  .outer-outline {
    outline: 2px solid black;
    display: inline-block;
    border-radius: 20px;
  }

  .data {
    /* margin-left: 9%; */
    position: relative;
    top: 15px;
  }

  .sb {
    position: relative;
    top: -12px;
  }

  .but-1:hover {
    background-color: lightgrey;
  }

  .ch {
    margin-left: 4%;
    margin-top: 4%;
  }

  .ty {
    margin: 8%;
  }

  .txt {
    margin-left: 6%;
    margin-top: -3%;
  }

  .mm {
    margin-left: 2%;
    margin-top: 3%;
  }

  .gg {
    margin-top: 5px;
    margin-left: 3%;
  }

  .is {
    margin-top: -2%;
  }

  .liquid,
  .blah {
    color: lightgray;
  }

  .text2 {
    position: relative;
    left: 150px;
    top: -30px;
  }
}

/* Media query for screens larger than1024px wide */
@media screen and (min-width: 1048px) and (max-width: 1800px) {
  /* Update the styles here for screens larger than 1024px wide */
  .button-container {
    float: right;
    /* margin-right: -5%; */
    position: relative;
    top: 10px;
    /* left: -700px; */

    position: relative;
    top: -60px;

    margin-right: 12%;
    /* display: grid; */
    grid-row-gap: 20px;
  }

  .but-1 {
    width: 50px;
    height: 50px;
  }

  .outer-outline {
    outline: 2px solid black;
    display: inline-block;
    border-radius: 20px;
  }

  .data {
    /* margin-left: 4%; */
    position: relative;
    top: 20px;
  }

  .sb {
    position: relative;
    top: -15px;
  }

  .but-1:hover {
    background-color: lightgrey;
  }

  .ch {
    margin-left: -10%;
    margin-top: 5%;
  }

  .ty {
    margin-left: 4%;
    margin-top: 7%;
  }

  .txt {
    margin-left: 7%;
    margin-top: -4%;
  }

  .mm {
    margin-left: 2%;
    margin-top: 3%;
  }

  .gg {
    margin-top: 5px;
    margin-left: 3%;
  }

  .is {
    margin-top: -2.5%;
  }

  .liquid,
  .blah {
    color: lightgray;
  }

  .text2 {
    position: relative;
    left: 200px;
    top: -50px;
  }
}

@media screen and (max-width: 1049px) and (min-width: 719px) {
  /* Update the styles here for screens larger than 1024px wide */

  .button-container {
    float: right;
    /* margin-right: -30%; */
    position: relative;
    top: 10px;

    position: relative;
    top: -60px;

    margin-right: 12%;
    /* display: grid; */
    grid-row-gap: 20px;
  }

  .but-1 {
    width: 50px;
    height: 50px;
  }

  .outer-outline {
    outline: 2px solid black;
    display: inline-block;
    border-radius: 20px;
  }

  .data {
    /* margin-left: 4%; */
    position: relative;
    top: 20px;
  }

  .sb {
    position: relative;
    top: -15px;
  }

  .but-1:hover {
    background-color: lightgrey;
  }

  .ch {
    margin-left: -15%;
    margin-top: 5%;
  }

  .ty {
    margin-left: 4%;
    margin-top: 7%;
  }

  .txt {
    margin-left: 7%;
    margin-top: -4%;
  }

  .mm {
    margin-left: 2%;
    margin-top: 3%;
  }

  .gg {
    margin-top: 5px;
    margin-left: 3%;
  }

  .is {
    margin-top: -2.5%;
  }

  .liquid,
  .blah {
    color: lightgray;
  }

  .text2 {
    position: relative;
    left: 200px;
    top: -50px;
  }
}

@media screen and (max-width: 720px) and (min-width: 470px) {
  .think {
    display: none;
  }

  .cardsizing-pool {
    /* width: 50%; */
    width: 32rem;
    /* height: auto; */
    height: 15rem;
    border-radius: 20px;
  }
  .liquid {
    display: none;
  }
}

@media screen and (max-width: 465px) and (min-width: 300px) {
  /* Update the styles here for screens up to 600px wide */
  .button-container {
    float: none;
    margin-top: 6%;
    /* margin-right: -110%; */
    position: relative;

    position: relative;
    top: -60px;

    margin-right: 12%;
    /* display: grid; */
    grid-row-gap: 20px;
  }

  .think {
    display: none;
  }

  .ppp {
    /* margin-left: 5%; */
    position: relative;
    top: 30px;
  }

  .but-1 {
    width: 150px;
    height: 150px;
    size: 20px;
  }

  .outer-outline {
    outline: none;
    border: 2px solid black;
    border-radius: 20px;
  }

  .data {
    /* margin-left: 5%; */
    top: -20px;
  }

  .row2 {
    position: relative;
    right: -70px;
  }

  .io {
    position: relative;
    left: -195px;
    top: -230px;
  }

  .sb {
    top: -10px;
    font-size: 32px;
    font-weight: 300;
  }

  .but-1:hover {
    background-color: lightgray;
  }

  .ch {
    margin-left: 3%;
    margin-top: 3%;
  }

  .ty {
    margin-left: -20%;
  }

  .txt {
    margin-left: 5%;
    margin-top: -2%;
    position: relative;
    right: -15px;
    top: -15px;
  }

  .mm {
    margin-left: 1%;
    margin-top: 2%;
    position: relative;
    right: -65px;
  }

  .gg {
    margin-top: -25px;
    margin-left: 2%;
  }

  .baba {
    position: relative;
    top: -95px;
    font-size: 15px;
    right: -60px;
  }
  .sho {
    position: relative;
    left: -190px;
    top: -133px;
  }
  .yoyo {
    position: relative;
    right: -50px;
    top: -10px;
  }

  .jh {
    position: relative;
    top: -143px;
    left: -175px;
  }

  .chu {
    position: relative;
    top: -5px;
  }

  .kl {
    position: relative;
    right: -30px;
    top: -10px;
  }

  .is {
    margin-top: -1.5%;
  }

  .blah {
    color: lightgray;
    font-size: 15px;
  }

  a {
    position: relative;
    right: -250px;
    top: -15px;
  }
  .text2 {
    width: 90px;
  }
  .yu {
    font-size: xx-large;
    font-weight: 500;
  }
  .dd {
    position: relative;
    display: none;
  }
  .liquid {
    display: none;
  }
}


.container-card-trade {
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 90px;
  /* height: 900px; */
}

.content-section {
  display: flex;
  justify-content: center;
  align-items: left;
  text-align: justify;
}

.content-section .card {
  flex: 1;
  margin: 20px 20px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  width: 700px;
  height: 250px;
}

.content-section h2 {
  font-size: 28px;
  color: #1662a1;
  font-family: sans-serif;
  position: relative;
}

.content-section p {
  font-size: 16px;
  font-family: sans-serif;
}

.r1 {
  display: flex;
  position: relative;
  top: 10px;
}
.java {
  position: relative;
  left: 400px;
}

.chachu,
.mus {
  position: relative;
  left: 225px;
  top: -50px;
}

.total {
  position: relative;
  top: 8px;
}

.pso {
  position: relative;
  top: 40px;
}


@media screen and (max-width: 764px) {
  .content-section {
    flex-direction: column;
  }
  .content-section .card {
    width: 150%;
  }
  .r1 {
    position: relative;
    right: 7px;
  }
  .java {
    position: relative;
    left: 20px;
  }

  .pso,
  .total {
    position: relative;
    left: 70px;
  }

  .chachu,
  .mus {
    position: relative;
    left: 110px;
  }
  .steel {
    margin-top: 30px;
  }
  .think {
    display: none;
  }
  .liquid {
    display: none;
  }
  .button-container {
    top: -30px;
  }
}

@media screen and (max-width: 465px) {
  .content-section {
    flex-direction: column;
  }
  .content-section .card {
    width: 150%;
  }

  .button-container {
    position: relative;
    left: 270px;
    top: -70px;
  }

  .think {
    display: none;
  }

  .java {
    position: relative;
    left: -120px;
    top: 15px;
    width: 38%;
  }
  .r1 {
    position: relative;
    right: 80px;
  }
  .liquid {
    display: none;
  }
}
@media screen and (max-width: 299px) {
  .container-card-trade {
    overflow: hidden;
  }
  .liquid {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .pso,
  .total {
    display: inline;
    position: relative;
    left: 115px;
    font-size: 13px;
  }

  .button-container {
    position: relative;
    left: 270px;
    top: -70px;
  }

  .chachu,
  .mus {
    position: relative;
    left: 40px;
    font-size: 13px;
  }

  .r1 {
    position: relative;
    left: -45px;
  }

  .java {
    position: relative;
    left: -220px;
  }

  .think {
    display: none;
  }
  .liquid {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .button-container {
    position: relative;
    left: 150px;
  }
}

@media screen and (max-width: 410px) {
  .button-container {
    left: 130px;
  }
}
@media screen and (max-width: 280px) {
  .button-container {
    left: 20px;
    top: 10px;
  }
}


@media (max-width: 1700px) and (min-width: 800px) {
  .imgdecoration {
    padding-left: 100px;
    padding-right: 100px;
    width: 100%;
    height: auto;
    margin-top: 15px;
  }
}

@media (max-width: 799px) and (min-width: 573px) {
  .imgdecoration {
    padding-left: 100px;
    padding-right: 100px;
    width: 100%;
    height: auto;
    margin-top: 5px;
  }
}

@media (max-width: 574px) and (min-width: 290px) {
  .imgdecoration {
    padding-left: 100px;
    padding-right: 100px;
    width: 100%;
    height: auto;
    margin-top: 4px;
  }
}