.Data_row {
  display: flex;
  justify-content: space-between;
}

.btn_pool {
  display: flex;
}

.btn_pool2 {
  display: flex;
}

.grey-button {
  background-color: grey;
}

.poolstyling-by-maaz {
  font-size: 55px;
  font-weight: 400;
}
.pool-heading-paragraph {
  font-size: 10px;
  font-style: italic;
}

.Row_1 {
  display: flex;
  margin-left: 35px;
  justify-content: space-between;
}

.right_row {
  display: flex;
}

p {
  color: lightgray;
  margin-bottom: 0;
}

.btn-6,
.btn-7 {
  border: none;
  width: 50px;
  color: black;
  background-color: #113974;
  height: 50px;
  border-radius: 20px;
  text-align: center;
}

.btn-6-active,
.btn-7-active {
  background-color: rgb(149, 189, 202);
}

.btn_pool,
.btn_pool2 {
  outline: 2px solid black;
  border-radius: 20px;
}

.pool_whole {
  margin-right: 50px;
  margin-left: 50px;
  margin-top: 50px;
}

.ddb {
  display: flex;
  justify-content: end;
}

.Data_row {
  margin-top: 60px;
}

.btn6_outline,
.btn7_outline {
  margin-top: 10px;
}

.graphy {
  display: flex;
}

.pool-btn-vs-adjust-width {
  height: 50px;
  background-color: rgb(57, 11, 171); /* Set the background color */
  color: rgb(229, 221, 221); /* Set the text color */
  border: 2px solid rgb(234, 234, 242); /* Set the outline border color and width */
  border-radius: 10px; /* Adjust the border radius as needed */
  padding: 10px 20px;
  align-items: center;
  display: flex;
  margin-right: 40%;
}

.but-of-uper-table-outline {
  display: flex;
  /* flex-direction: column; */
  /* justify-content: flex-start; */
  align-items: center;
  background-color: white;
  padding-left: 10%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.but-of-uper-table {
  display: flex;
  height: 50px;
  background-color: rgb(35, 39, 42); /* Set the background color */
  color: rgb(255, 255, 255); /* Set the text color */
  border: 2px solid rgb(255, 255, 255); /* Set the outline border color and width */
  border-radius: 10px; /* Adjust the border radius as needed */
  padding: 10px 20px;
  /* padding-bottom: 60px; */
  /* align-items: center; */
  margin-right: 10px;
}

.but-of-uper-table:hover {
  background-color: rgb(35, 105, 255);
  color: black;
}

.table-custom-size {
  margin-left: 10%;
  margin-right: 10%;
}

@media (max-width: 858px) {
  .btn-6,
  .btn-7,
  .btn6_outline,
  .btn7_outline {
    display: none;
  }
}

@media (max-width: 767px) {
  .Data_row {
    display: block;
  }

  .left_row {
    padding-bottom: 14px;
  }
}

@media screen and (max-width: 446px) {
  .Row_1 {
    margin-left: 0px;
    align-items: center;
    padding-bottom: 12px;
  }
  .pool_whole {
    margin-right: 0px;
    margin-left: 10px;
  }
  .left_row {
    padding-bottom: 14px;
  }
  .wrapper_pool {
    padding-bottom: 14px;
  }
  .Data_row {
    padding-bottom: 20px;
  }
  .pool-btn-vs-adjust-width {
    padding: 8px 8px;
  }

  .table-custom-size {
    margin-left: 0px;
    margin-right: 0px;
  }

  .but-of-uper-table-outline {
    justify-content: space-evenly;
    padding-left: 0%;
  }
}

@media screen and (max-width: 650px) {
  .table-custom-size {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 350px) {
  .table-custom-size {
    margin-left: 0%;
    margin-right: 0%;
  }
}