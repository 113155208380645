
.bridge-nav-collect-btn {

    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    background-color: green;
    height: 50px;
    border-radius: 18px;
    width: 180px;
    justify-content: center;
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
        
}
.outlined-input {
    border: 2px solid #ccc; /* Border style */
    border-radius: 5px; /* Rounded corners */
    padding: 10px; /* Padding inside the input field */
    width: 300px; /* Width of the input field */
    font-size: 16px; /* Font size */
    background-color: antiquewhite;
    color: black;
  }


.bridge-nav-collect-btn:hover {
    width: 190px;
  transition: 0.20s;
   cursor: pointer;
   height: 55px;
        
}