@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
body{
	line-height: 1.5;
	font-family: 'Poppins', sans-serif;
}

*{
	margin:0;
	padding:0;
	box-sizing: border-box;
}
.container{
	max-width: 1800px;
	margin:auto;
	/* padding: 50px; */
}
.row{
	display: flex;
	flex-wrap: wrap;
}
.footerinnerboxes{
	
	list-style: none;
	padding-left: 0px;
}
.footerineritemssyt{
	color: black;
}
.footer{
	background-color: #656571;
    padding-top: 20px;
	padding-bottom: 40px;
}
.footer-col{
   width: 25%;
   padding: 0 15px;
}
.footer-col h4{
	font-size: 20px;
	color: black;
	text-transform: capitalize;
	margin-bottom: 35px;
	font-weight: 500;
	position: relative;
	
	font-weight: bolder;
}
.footer-col h4::before{
	content: '';
	position: absolute;
	left:0;
	bottom: -10px;
	background-color: #e91e63;
	height: 2px;
	box-sizing: border-box;
	width: 100px;
}
.footer-col ul li:not(:last-child){
	margin-bottom: 10px;
}
.footer-col ul li a{
	font-size: 16px;
	text-transform: capitalize;
	color: #332f2f;
	text-decoration: none;
	font-weight: 300;
	
	display: block;
	transition: all 0.3s ease;
}
.footer-col ul li a:hover{
	color: #555252;
	padding-left: 8px;
}
.footer-col .social-links a{
	display: inline-block;
	height: 40px;
	width: 40px;
	background-color: rgba(255,255,255,0.2);
	margin:0 10px 10px 0;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	color: #ffffff;
	transition: all 0.5s ease;
}

span {
	color: rgb(133, 109, 214);
	font-weight: bold;
}

.footer-col .social-links a:hover{
	color: #24262b;
	background-color: #ffffff;
}

/*responsive*/



@media(max-width: 1700px) and (min-width : 800px ){
	.footer-col{
	  width: 25%;
	  
	  margin-bottom: 30px;
  }
  }

@media(max-width: 767px){
  .footer-col{
    width: 50%;
    margin-bottom: 30px;
}
}
@media(max-width: 574px){
  .footer-col{
    width: 100%;
}
}




